<template>
    <main class="swcluster-main" id="swcluster-datahub">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <CommonBreadcrumb :show-title="true">
                    <template v-slot:title-img>
                        <div class="title-img">
                            <img src="@/assets/images/datahub/datahub_logo_1.svg" alt="한국정보통신기술협회 로고" width="177" height="100" />
                        </div>
                    </template>
                </CommonBreadcrumb>
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-contents -->
            <div class="page-contents">
                <div class="board-view-container">
                    <div class="board-body">
                        <h4 class="view-title">개요</h4>
                        <p class="view-description">
                            ISO/IEC 25023, 25024, 29119 등 국제표준을 기반으로 데이터 및 SW의 품질 수준 진단하고, 실무중심의 품질 향상 방안과 산출물 작성 가이드
                            지원
                        </p>
                        <p class="view-description">
                            * 관련 표준: ISO/IEC 25023(SW 품질 측정), ISO/IEC 25024(데이터 품질 측정), ISO/IEC 29119(SW 테스트 프로세스)
                        </p>
                        <h4 class="view-title">컨설팅 목적</h4>
                        <p class="view-description">
                            제3자 SW시험·인증기관인 TTA(한국정보통신기술협회)의 객관적인 컨설팅을 통해 컨설팅 대상 기업의 테스트 프로세스 및 데이터 품질 관련
                            문제점을 진단하고, 품질 수준 및 프로세스 개선을 위한 가이드라인을 제시함으로써 제품의 품질 향상 및 대외 경쟁력 제고
                        </p>
                        <h4 class="view-title">컨설팅 대상</h4>
                        <p class="view-description">데이터 품질 및 SW 테스트 프로세스 고도화가 필요한 기업(관)</p>
                        <h4 class="view-title">컨설팅 방법</h4>
                        <p class="view-description">
                            1. (데이터 품질 컨설팅) 플랫폼에서 활용할 수 있도록 각종 생산/수집되는 정형 데이터(수집 정보)에 대한 비식별화 방안, 샘플 데이터
                            분석을 통한 품질 개선안, 표준 기반 데이터 포맷 개선 가이드 등 컨설팅 수행
                        </p>
                        <p class="view-description">
                            2. (SW 품질 컨설팅) 체계적 SW 테스트 절차와 문서화, 방법 지원을 위한 SW 개발 생명주기 전 과정에 대한 컨설팅 수행
                        </p>
                        <p class="view-description">* 컨설팅은 ‘One-point’ 형태로 진행(역량평가, 취약점 분석, 품질 목표 수립, 산출물 가이드 등)</p>
                        <h4 class="view-title">컨설팅 절차</h4>
                        <p class="view-description">
                            <img src="@/assets/images/datahub/datahub_consulting.svg" alt="" />
                        </p>
                        <h4 class="view-title">문의처</h4>
                        <p class="view-description">TTA 이재훈 책임, 010-5110-3013, jlee@tta.or.kr</p>
                    </div>
                </div>
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <!-- page-bottom -->
        <div class="page-bottom bottom-fixed">
            <div>
                <div class="bottom-row-container bottom-component">
                    <div class="bottom-side">
                        <button class="btn-action btn-primary" @click="goApply"><span class="text">신청하기</span></button>
                    </div>
                    <div v-if="session.manager" class="bottom-side">
                        <button class="btn-action" @click="goStatus"><span class="text">목록</span></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- //page-bottom -->
    </main>
</template>

<script>
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
import {useRouter} from 'vue-router';
import {computed} from 'vue';
import {getAuthSession} from '@/assets/js/modules/auth/module';

export default {
    name: 'Consulting',
    components: {CommonBreadcrumb},
    setup() {
        const session = computed(getAuthSession);
        const router = useRouter();

        const goApply = e => {
            if (e.ctrlKey) {
                window.open('/consulting/apply');
            } else {
                router.push({name: 'ConsultingApply'});
            }
        };

        const goStatus = e => {
            if (e.ctrlKey) {
                window.open('/consulting/status');
            } else {
                router.push({name: 'ConsultingStatus'});
            }
        };

        return {
            session,
            goApply,
            goStatus,
        };
    },
};
</script>
